import React, { useEffect, useRef } from 'react'
import loadScript from '../../utils/utils'

const GartnerWidget = ({ widget_id }) => {
  const widgetRef = useRef(null)

  useEffect(() => {
    widget_id &&
      loadScript(
        'https://www.gartner.com/reviews/technology-providers/public/Widget/js/widget.js',
        'gartner-widget-script',
      )

    const scriptLoadCheck = setInterval(() => {
      if (window.GartnerPI_Widget) {
        clearInterval(scriptLoadCheck)
        window.GartnerPI_Widget({
          size: 'large',
          theme: 'light',
          sourcingLink: 'https://gtnr.io/0PAxXKrnh',
          widget_id: widget_id,
          version: '2',
          container: widgetRef.current,
        })
      }
    }, 100)

    return () => clearInterval(scriptLoadCheck)
  }, [])

  return <div ref={widgetRef} />
}

export default GartnerWidget
